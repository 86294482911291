import React, { Fragment } from "react";




import Header from '../components/Header';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function Landing() {
  return (
    <Fragment>
      <div>
        <Header />
        <AboutUs />
        <Services />
        <Contact />
        <Footer />
      </div>
    </Fragment>
  )
}

export default Landing


