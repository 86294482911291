import React, { Fragment } from "react";
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Contact() {
  return (
    <Fragment>
      <div className="parallax-content contact-content" id="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.790838776582!2d103.8347303745308!3d1.3003414617339042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1990e64233c3%3A0x195e23319b8764f3!2s111%20Somerset%20Rd%2C%20%2303-09%2C%20Singapore%20238164!5e0!3m2!1sen!2sid!4v1681178065256!5m2!1sen!2sid"
                  width="100%" height="390" allowFullScreen></iframe>
              </div>
            </div>
            <div className='col-md-6 contact-us'>
              <h2>Contact Us</h2>
              <div className='row no-wrap'>
                <div className='footer-icon-container'><FontAwesomeIcon icon={faEnvelope} size={'sm'} /></div>
                <div className='footer-cu-spacer' />
                <div>info@asiapacificaerosupport.com</div>
              </div>
              <div className='row no-wrap'>
                <div className='footer-icon-container'><FontAwesomeIcon icon={faLocationDot} size={'sm'} /></div>
                <div className='footer-cu-spacer' />
                <div>111 SOMERSET ROAD, #03-09, 111 SOMERSET, Singapore 238164</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}