import React from "react";
import Landing from "./screens/Landing.jsx";

import './style/css/hero-slider.css';
import './style/css/App.css';

function App() {
  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
