import React, { Fragment } from "react";

export default function Footer() {
  return (
    <Fragment>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>Copyright &copy; {new Date().getFullYear()} Asia Pacific Aero Support Pte. Ltd.</p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}