import React, { Fragment } from "react";
import img1 from '../assets/img/1st-big-item.webp';
import img2 from '../assets/img/2nd-big-items.webp';
import img3 from '../assets/img/3rd-big-items.webp';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Services() {
  return (
    <Fragment>
      <div className="parallax-content projects-content" id="portfolio">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={1000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <div className="slider-items">
                  <div className="img-content">
                    <img src={img1} alt="" />
                  </div>
                  <div className="text-content">
                    <p>PT6A Engine Spareparts Support</p>
                  </div>
                </div>
                <div className="slider-items">
                  <div className="img-content">
                    <img src={img2} alt="" />
                  </div>

                  <div className="text-content">
                    <p>Cessna Caravan Spareparts Support</p>
                  </div>
                </div>
                <div className="slider-items">
                  <div className="img-content">
                    <img src={img3} alt="" />
                  </div>
                  <div className="text-content">
                    <p>Propeller Spareparts support</p>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}