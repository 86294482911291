import React, { Fragment, useEffect, useState } from "react";
import ArrowUp from '../assets/svg/ArrowUp.jsx';

export default function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <Fragment>
      <div>
        {
          isVisible ? <div className='back-top' onClick={scrollToTop}>
            <ArrowUp onClick={scrollToTop} />
          </div> : null
        }

        <div className="fixed-side-navbar">
          <ul className="nav flex-column">
            <li className="nav-item"><a className="nav-link" href="#home"><span>Intro</span></a></li>
            <li className="nav-item"><a className="nav-link" href="#services"><span>About Us</span></a></li>
            <li className="nav-item"><a className="nav-link" href="#portfolio"><span>Services</span></a></li>
            <li className="nav-item"><a className="nav-link" href="#contact-us"><span>Contact Us</span></a></li>
          </ul>
        </div>

        <div className="parallax-content baner-content" id="home">
          <div className="container">
            <div className="first-content">
              <h1>Asia Pacific Aero Support</h1>
              <div className="primary-button">
                <a href="#services">Discover More</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );

}